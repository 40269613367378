const ModalFooter = props => {
  const { children, style, ...rest } = props;
  return (
    <div className="r-modal__footer" style={style}>
      {children}
    </div>
  );
};

export default ModalFooter;
