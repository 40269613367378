import React from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { CardBody, CardFooter } from 'components/card';
import { List, ListBody } from 'components/list';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { flexRender } from '@tanstack/react-table';
import { Button, ButtonsGroup } from 'components/button';
import ModalHeader from 'components/modal/ModalHeader';
import ModalBody from 'components/modal/ModalBody';
import ModalFooter from 'components/modal/ModalFooter';

const ColumnVisibilityModal = ({ table, onClose }) => {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal refactoring isOpen={true} onClose={onCloseHandler}>
      <ModalHeader>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.growthtrackerColumnVisibilityModalTitle}
          />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage
            {...messages.growthtrackerColumnVisibilityModalSubTitle}
          />
        </CardHeaderSubtitle>
      </ModalHeader>
      <ModalBody>
        <List onModal>
          <ListBody>
            {table.getAllLeafColumns().map(column => {
              if (!column.getCanHide()) return;
              return (
                <ListItem
                  key={column.id}
                  //draggable
                  outfaded={!column.getIsVisible()}
                  onClick={() => column.toggleVisibility()}
                >
                  <ListItemStatus
                    itemId={column.id}
                    visible={column.getCanHide()}
                    disabled={!column.getCanHide()}
                    checked={column.getIsVisible()}
                    onChange={() => column.toggleVisibility()}
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      {flexRender(
                        column.columnDef.meta
                          ? column.columnDef.meta?.longLabel
                          : column.columnDef.header
                      )}
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              );
            })}
          </ListBody>
        </List>
      </ModalBody>
      <ModalFooter>
        <ButtonsGroup>
          <Button primary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.buttonClose} />
          </Button>
          {/* <Button
            type="button"
            primary
            disabled={}
            onClick={() => ()}
          >
            SAVE
            />
          </Button> */}
        </ButtonsGroup>
      </ModalFooter>
    </Modal>
  );
};

export default ColumnVisibilityModal;
