import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumb } from 'components/breadcrumbs';
import messages from 'messages';
import { ResultsModalView } from 'containers/pages/results/modals/results-modal-view';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import ResultsMainModal from 'containers/pages/results/modals/ResultsMainModal';
import ResultsPanel from 'containers/pages/results/ResultsPanel';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import Loader from 'components/loader/Loader';
import { Panel } from 'components/panel';
import ResultsPanelHeader from 'containers/pages/results/ResultsPanelHeader';
import Modal from 'components/modal/Modal';
import BenchmarkAlertCardBody from 'containers/partials/cards/BenchmarkAlertCardBody';
import SidePanelChartCard from 'containers/partials/cards/SidePanelChartCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';

const GroupResults = ({ groupId }) => {
  const {
    authStore: { user, entity }
  } = useContext(StoreContext);

  const intl = useIntl();
  const { push } = useHistory();

  const group = useEntityContext(s => s.group);

  const {
    loading: loadingGroups,
    error,
    fetchEntities
  } = useFetchAndFilterEntities({ entityId: groupId });

  const {
    loading: loadingResults,
    sidePanelData,
    onCloseSidePanel,
    error: resultsError
  } = useResultsPanelContext();

  const [modalData, setModalData] = useState(null);
  const [alertData, setAlertData] = useState(null);

  useEffect(() => {
    if (groupId) {
      fetchEntities(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (!loadingGroups && group?.clients?.length === 0 && group.id) {
      setAlertData({
        dismissHandler: () => onCloseAlert(),
        okHandler: () =>
          push(ROUTE_SPORTERS_ENTITY.replace(':entityId', groupId)),
        okLabel: intl.formatMessage(messages.benchmarksAlertButtonGoToSporters),
        dismissLabel: intl.formatMessage(messages.benchmarksAlertButtonGoBack),
        message: intl.formatMessage(messages.benchmarksAlertMessageEmptyGroup)
      });
    } else {
      setAlertData(null);
    }
  }, [loadingGroups, group.clients, group.id]);

  useEffect(() => {
    if (error) {
      setAlertData({
        dismissHandler: () => setAlertData(null),
        okHandler: () => setAlertData(null),
        okLabel: intl.formatMessage(messages.benchmarksAlertButtonNoBenchmark),
        message: intl.formatMessage(messages.benchmarksAlertMessageNoBenchmark)
      });
    }
  }, [error]);

  useEffect(() => {
    if (resultsError) {
      setAlertData({
        dismissButton: false,
        okHandler: () => onCloseAlert(null),
        okLabel: intl.formatMessage(messages.benchmarksAlertButtonTimeout),
        message: intl.formatMessage(messages.benchmarksAlertMessageTimeout)
      });
    }
  }, [resultsError]);

  const toggleSelectGroupModal = (view, isMaster, submitHandler) => {
    if (modalData) {
      setModalData(null);
    } else {
      setModalData({
        view: view ? null : view,
        modalMaster: isMaster,
        submitHandler: view ? null : submitHandler
      });
    }
  };

  const onCloseAlert = () => {
    setAlertData(null);
    toggleSelectGroupModal(ResultsModalView.GROUP, true);
  };

  if (!group) return <Loader />;

  return (
    <>
      {modalData && (
        <ResultsMainModal
          entityId={user.rootEntityId}
          onCloseHandler={() => {
            setModalData(null);
          }}
          view={modalData.view}
          master={modalData.modalMaster}
          onSubmitHandler={modalData.submitHandler}
        />
      )}
      {loadingResults && <Loader />}
      <Panel benchmark>
        {group?.id && (
          <ResultsPanelHeader
            breadcrumbs={getBreadCrumbs(
              toggleSelectGroupModal,
              group,
              user,
              entity
            )}
          />
        )}
        <ResultsPanel />
      </Panel>
      {alertData && (
        <Modal
          card
          benchmarksTableAlert
          noCloseButton
          isOpen={true}
          onClose={onCloseAlert}
        >
          <BenchmarkAlertCardBody alertData={alertData} />
        </Modal>
      )}
      {sidePanelData && (
        <Modal cardPanel isOpen={true} onClose={onCloseSidePanel}>
          <SidePanelChartCard {...sidePanelData} />
        </Modal>
      )}
    </>
  );
};

export default GroupResults;

const getBreadCrumbs = (gotoHandler, group, user, entity) => {
  return (
    <>
      <Breadcrumb noLink>
        <FormattedMessage {...messages.benchmarksBreadcrumbGroups} />
      </Breadcrumb>
      <Breadcrumb onClick={() => gotoHandler(ResultsModalView.GROUP, true)}>
        {user.getEntityName(entity)}
      </Breadcrumb>
      <Breadcrumb onClick={() => gotoHandler(ResultsModalView.GROUP, true)}>
        {group.name}
      </Breadcrumb>
    </>
  );
};
