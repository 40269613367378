import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import RefactoredTableHeaderCell from 'components/table/RefactoredTableHeaderCell';

const GrowthTableHeaderCell = ({ header, index, children }) => {
  return (
    <RefactoredTableHeaderCell
      sortedBy={index >= 1 && header.column.getIsSorted()}
      mainCell={index >= 1}
      key={header.id}
      style={{
        ...(index >= 1
          ? {
              width: `${header.getSize()}px`,
              minWidth: `${header.getSize()}px`,
              padding: '0 8px'
              //   boxSizing: 'content-box'
            }
          : {})
      }}
    >
      <div className="r-table-cell--complex-label">{children}</div>
      {header.column.getCanSort() && (
        <Button
          tiny
          // inactive={!isSortIndex}
          onClick={header.column.getToggleSortingHandler()}
          // disabled={!header.id}
        >
          <Icon
            id={
              header.column.getIsSorted()
                ? `sort-${header.column.getIsSorted().toString() === 'desc' ? 'down' : 'up'}`
                : 'sort'
            }
          />
        </Button>
      )}
    </RefactoredTableHeaderCell>
  );
};

export default GrowthTableHeaderCell;
