const ModalHeader = props => {
  const { children, style, ...rest } = props;

  return (
    <div className="r-modal__header" style={style}>
      {children}
    </div>
  );
};

export default ModalHeader;
