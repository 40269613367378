import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { TableHeaderCell, TableRow, TableHeader } from 'components/table';
import { GrowthPredictionPanelConsumer } from '../GrowthPredictionPanel';
import messages from 'messages';

class GrowthPredictionTableHeader extends Component {
  render() {
    return (
      <GrowthPredictionPanelConsumer>
        {() => (
          <TableHeader>
            <TableRow>
              <TableHeaderCell extraClassNames={'c-table__content'} />
              <TableHeaderCell extraClassNames="c-input__label">
                <FormattedMessage
                  {...messages.growthPredictionGrowthVelocity}
                  values={{ isShort: false, br: () => <br /> }}
                />
              </TableHeaderCell>
              <TableHeaderCell extraClassNames="c-input__label">
                <FormattedMessage
                  {...messages.growthPredictionWeightVelocity}
                />
              </TableHeaderCell>
              <TableHeaderCell extraClassNames="c-input__label">
                <FormattedMessage {...messages.growthPredictionAdultHeight} />
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
        )}
      </GrowthPredictionPanelConsumer>
    );
  }
}

export default withRouter(
  injectIntl(
    inject('routing', 'authStore')(observer(GrowthPredictionTableHeader))
  )
);
