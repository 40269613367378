import gql from 'graphql-tag';
import { gqlMutation } from 'services/aws/utils/mutate';
import { appSyncClient } from 'services/aws/app-sync';
import { getGQLResult } from 'services/aws/utils/get';

export const QUERY_GET_GROWTH_DATA_OF_PERSON = gql`
  query getGrowthDataOfPerson($personId: ID!, $entityId: ID!) {
    getGrowthDataOfPerson(personId: $personId, entityId: $entityId) {
      person {
        id
        firstname
        lastname
        birthdate
      }
      preciseAge
      testItemValues
      heightEvolutionData
      weightEvolutionData
    }
  }
`;

export const QUERY_GET_GROWTH_DATA_OF_GROUP = gql`
  query getGrowthDataOfGroup2($entityId: ID!) {
    getGrowthDataOfGroup2(entityId: $entityId) {
      person {
        id
        firstname
        lastname
        birthdate
      }
      preciseAge
      testItemValues
      heightEvolutionData
      weightEvolutionData
    }
  }
`;

export const QUERY_GET_GROWTH_EVOLUTION_OF_PERSON = gql`
  query getGrowthEvolutionOfPerson($personId: ID!, $entityId: ID!) {
    getGrowthEvolutionOfPerson(personId: $personId, entityId: $entityId) {
      person {
        id
        firstname
        lastname
        birthdate
      }
      preciseAge
      testItemValues
      heightEvolutionData
      weightEvolutionData
    }
  }
`;

export const QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP = gql`
  query downloadGrowthDataOfGroup($entityId: ID!, $meta: AWSJSON) {
    downloadGrowthDataOfGroup(entityId: $entityId, meta: $meta)
  }
`;

export const QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP_AND_PERSONS = gql`
  query downloadGrowthDataOfGroupAndPersons($entityId: ID!, $meta: AWSJSON) {
    downloadGrowthDataOfGroupAndPersons(entityId: $entityId, meta: $meta)
  }
`;

export const QUERY_MAIL_GROWTH_DATA_OF_PERSONS_TO_PERSONS = gql`
  query mailGrowthDataOfPersonToAllAthletes($entityId: ID!, $meta: AWSJSON) {
    mailGrowthDataOfPersonToAllAthletes(entityId: $entityId, meta: $meta)
  }
`;

export const QUERY_DOWNLOAD_GROWTH_DATA_OF_PERSON = gql`
  query downloadGrowthDataOfPerson($personId: ID!, $entityId: ID!) {
    downloadGrowthDataOfPerson(personId: $personId, entityId: $entityId)
  }
`;

export const downloadPersonGrowthReport = async (personId, entityId) => {
  return await getGQLResult(
    appSyncClient,
    QUERY_DOWNLOAD_GROWTH_DATA_OF_PERSON,
    {
      personId,
      entityId
    }
  );
};

export const QUERY_GET_INPUT_DATA_OF_SPORTER = gql`
  query getInputData($personId: ID!) {
    getInputData(
      personId: $personId # "5efda0d2-de1c-4f2a-b5bb-63e2febc2c37"
    ) {
      id
      testItemId
      values
      testDateTime
    }
  }
`;

export const MUTATION_REMOVE_DATA_POINT = gql`
  mutation removeDataPoint($id: ID!) {
    removeInputData(inputDataId: $id)
  }
`;

export const removeDataPoint = async (id, refetchQueries) => {
  const mutation = MUTATION_REMOVE_DATA_POINT;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables: { id },
      refetchQueries,
      update: (cache, { data: { removeInputData } }) => {
        if (removeInputData) {
          resolve(true);
        }
      }
    });
  });
};
