import { appSyncClient } from './app-sync';
import gql from 'graphql-tag';
import { gqlMutation } from './utils/mutate';
import { getGQLResult } from './utils/get';

export const QUERY_GET_USER_BY_ID = gql`
  query person($id: String!) {
    getPerson(id: $id) {
      id
      firstname
      lastname
      gender
      birthdate
      uid
      email
      meta
      account_meta
      integrations
      status @client
      dominantHand
      notes
      baseLanguage
    }
  }
`;

export const QUERY_GET_PERSONENTITIES_BY_PERSON_ID = gql`
  query person($id: String!) {
    getPerson(id: $id) {
      id
      entities {
        id
        baseLanguage
        entity {
          id
          name
          baseLanguage
          type
        }
        entityId
        roles
        roletype
        person_meta
      }
    }
  }
`;

export const QUERY_GET_USERS_BY_ENTITY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      users {
        id
        firstname
        lastname
        superadmin
        email
        baseLanguage
        meta
        account_meta
        integrations
        status @client
      }
    }
  }
`;

export const QUERY_GET_PERSONS_OF_ENTITY = gql`
  query getPersonsOfEntity($entityId: ID!) {
    getPersonsOfEntity(entityId: $entityId) {
      id
      firstname
      lastname
      gender
      birthdate
      uid
      email
      meta
      account_meta
      integrations
      status @client
      dominantHand
      notes
      baseLanguage
      entities {
        id
        baseLanguage
        entity {
          id
          name
          baseLanguage
          type
        }
        entityId
        roles
        roletype
        person_meta
      }
    }
  }
`;

export const QUERY_GET_PERSONENTITIES_OF_PERSON = gql`
  query getPersonEntitiesOfPerson($topLevelEntityId: ID!, $personId: ID!) {
    getPersonEntitiesOfPerson(
      topLevelEntityId: $topLevelEntityId
      personId: $personId
    ) {
      id
      entityId
      baseLanguage
      entity {
        id
        name
        baseLanguage
        type
      }
      roles
      roletype
      person_meta
    }
  }
`;

export const MUTATION_ADD_USER = gql`
  mutation addPersonToEntityReturnPerson(
    $entityId: ID!
    $uid: String!
    $topLevelEntityId: ID!
    $email: String!
    $firstname: String!
    $lastname: String!
    $roles: [PersonEntityRoles!]
    $baseLanguage: baseLanguage
  ) {
    addPersonToEntityReturnPerson(
      input: {
        entityId: $entityId
        topLevelEntityId: $topLevelEntityId
        person: {
          email: $email
          firstname: $firstname
          lastname: $lastname
          uid: $uid
        }
        roletype: user
        roles: $roles
        baseLanguage: $baseLanguage
      }
    ) {
      id
    }
  }
`;

export const MUTATION_ADD_PERSON_ENTITY = gql`
  mutation addPersonToEntity(
    $entityId: ID!
    $topLevelEntityId: ID!
    $person: PersonInput!
    $roles: [PersonEntityRoles]
  ) {
    addPersonToEntity(
      input: {
        entityId: $entityId
        topLevelEntityId: $topLevelEntityId
        person: $person
        roletype: user
        roles: $roles
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_USER = gql`
  mutation editPerson(
    $entityId: ID!
    $id: ID!
    $email: String!
    $firstname: String!
    $lastname: String!
  ) {
    editPerson(
      entityId: $entityId
      input: {
        id: $id
        email: $email
        firstname: $firstname
        lastname: $lastname
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_BASE_LANGUAGE = gql`
  mutation updateBaseLanguage($baseLanguage: baseLanguage) {
    updateBaseLanguage(baseLanguage: $baseLanguage) {
      id
    }
  }
`;

export const updateUserLanguage = async (variables, refetchQueries) => {
  const mutation = MUTATION_UPDATE_BASE_LANGUAGE;
  return await gqlMutation(appSyncClient, {
    mutation,
    variables,
    refetchQueries,
    update: (cache, { data: { updateBaseLanguage } }) => {
      if (updateBaseLanguage) {
        return updateBaseLanguage;
      }
    }
  });
};

export const MUTATE_UPDATE_USER_ROLES = gql`
  mutation updatePersonEntityRoles(
    $personEntityId: ID
    $personId: ID
    $entityId: ID
    $roles: [PersonEntityRoles!]
  ) {
    updatePersonEntityRoles(
      input: {
        personEntityId: $personEntityId
        entityId: $entityId
        personId: $personId
        roles: $roles
      }
    ) {
      id
    }
  }
`;

export const updateUserMutation = async ({ variables, refetchQueries }) => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATE_UPDATE_USER_ROLES,
      variables,
      refetchQueries,
      update: (cache, { data: { updatePersonEntityRoles } }) => {
        if (updatePersonEntityRoles) {
          resolve();
        }
      }
    });
  });
};

export const createLogin = async (personId, entityId, refetchQueries) => {
  const mutation = gql`
    mutation createLogin($personId: ID!) {
      createLogin(personId: $personId)
    }
  `;
  return await gqlMutation(appSyncClient, {
    mutation,
    variables: {
      personId
    },
    errorPolicy: 'all',
    refetchQueries
  });
};

export const REMOVE_PERSON_FROM_ENTITY = gql`
  mutation removePersonFromEntity($personId: ID!, $entityId: ID!) {
    removePersonFromEntity(personId: $personId, entityId: $entityId)
  }
`;

export const removePersonEntityMethod = async (variables, refetchQueries) => {
  const mutation = REMOVE_PERSON_FROM_ENTITY;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables,
      refetchQueries,
      update: (cache, { data: { removePersonFromEntity } }) => {
        if (removePersonFromEntity) {
          resolve(true);
        }
      }
    });
  });
};

export const QUERY_GET_ME = gql`
  query Me {
    getMe {
      id
      firstname
      lastname
      birthdate
      email
      superadmin
      date_created
      baseLanguage
      hmac
    }
  }
`;

export const QUERY_GET_ROOT_ORGANISATION = gql`
  query getRootOrganisation {
    getRootOrganisation {
      id
      name
      config
    }
  }
`;

/**
 * Get user me data
 */
export const getMe = async () => {
  return await getGQLResult(appSyncClient, QUERY_GET_ME);
};

export const getRootOrganisation = async () => {
  return await getGQLResult(appSyncClient, QUERY_GET_ROOT_ORGANISATION);
};

export const getEntityTree = async entityId => {
  const query = gql`
    query getEntityTree($entityId: ID!) {
      getEntityTree(entityId: $entityId)
    }
  `;
  return await getGQLResult(appSyncClient, query, { entityId });
};

export const getRoles = async (entityId, personId) => {
  const query = gql`
    query getPersonEntityRoles($entityId: ID!, $personId: ID!) {
      getPersonEntityRoles(entityId: $entityId, personId: $personId)
    }
  `;
  return await getGQLResult(appSyncClient, query, { entityId, personId });
};

export const getMeEntities = async () => {
  const query = gql`
    query Me {
      getMe {
        id
        entities {
          id
          baseLanguage
          entityId
          roles
          roletype
          entity {
            id
            name
            config
            info
            type
            testSets {
              id
              name
              type
              subtype
              config
              version
            }
            baseLanguage
            featureFlags
            apiKeys
            regioCode
            possibleLanguages {
              key
              language
            }
          }
        }
        baseLanguage
        hmac
      }
    }
  `;
  return await getGQLResult(appSyncClient, query);
};

export const getEntity = async id => {
  const query = gql`
    query entity($id: String!) {
      getEntity(id: $id) {
        id
        topLevelEntityId
        parentId
        name
        config
        info
        type
        baseLanguage
        featureFlags
        apiKeys
        regioCode
        possibleLanguages {
          key
          language
        }
      }
    }
  `;
  return await getGQLResult(appSyncClient, query, {
    id
  });
};

export const getPersonEntity = async (personId, entityId) => {
  const query = gql`
    query getPersonEntity($personId: ID!, $entityId: ID!) {
      getPersonEntity(personId: $personId, entityId: $entityId) {
        id
        personId
        entityId
        topLevelEntityId
        person {
          id
          firstname
          lastname
          baseLanguage
        }
        roles
        roletype
        entity {
          id
          name
          topLevelEntityId
          config
          info
          type
          regioCode
          baseLanguage
          possibleLanguages {
            key
            language
          }
          featureFlags
        }
        person_meta
        baseLanguage
      }
    }
  `;
  return await getGQLResult(appSyncClient, query, {
    personId,
    entityId
  });
};

export const getPersonEntityWithEntities = async (personId, entityId) => {
  const query = gql`
    query getPersonEntity($personId: ID!, $entityId: ID!) {
      getPersonEntity(personId: $personId, entityId: $entityId) {
        id
        personId
        entityId
        topLevelEntityId
        person {
          id
          firstname
          lastname
          baseLanguage
          entities {
            id
            baseLanguage
            entity {
              id
              name
              baseLanguage
              type
            }
            entityId
            roles
            roletype
            person_meta
          }
        }
        roles
        roletype
        entity {
          id
          name
          topLevelEntityId
          config
          info
          type
          regioCode
          baseLanguage
          possibleLanguages {
            key
            language
          }
          featureFlags
        }
        person_meta
        baseLanguage
      }
    }
  `;
  return await getGQLResult(appSyncClient, query, {
    personId,
    entityId
  });
};

export const getPersonEntitiesOfPerson = async personId => {
  return await getGQLResult(
    appSyncClient,
    QUERY_GET_PERSONENTITIES_BY_PERSON_ID,
    {
      id: personId
    }
  );
};

export const MUTATE_RESET_LOGIN = gql`
  mutation resetLogin($personId: ID!) {
    resetLogin(personId: $personId)
  }
`;

export const searchPerson = async (term, entityId) => {
  const searchPerson = gql`
    query entity($term: String!, $entityId: ID!) {
      searchPerson(term: $term, entityId: $entityId) {
        id
        person {
          firstname
          lastname
        }
      }
    }
  `;
  return await getGQLResult(appSyncClient, searchPerson, {
    term,
    entityId
  });
};

export const MUTATION_GENERATE_MAGIC_LINK = gql`
  mutation createMagicLink($personId: ID!) {
    createMagicLink(personId: $personId)
  }
`;

export const MUTATION_DECRYPT_MAGIC_LINK = gql`
  mutation decryptMagicLink($token: String!) {
    decryptMagicLink(token: $token)
  }
`;
