import { Fragment, useEffect, useState } from 'react';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import LineChart from '../charts/LineChart';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { Lateralities } from 'constants.js';
import { capitalize } from 'utils/string';
import Loader from 'components/loader/Loader';

const SidePanelChartCard = ({
  title,
  subTitle = '',
  person,
  testItemId,
  unit,
  laterality,
  chartData,
  errorMessage = '',
  dataSrc
}) => {
  const [chartDataState, setChartDataState] = useState(chartData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      const result = await dataSrc({ person, testItemId, unit });
      setLoading(false);
      setChartDataState({
        ...chartData,
        data: [
          {
            id: testItemId,
            data: [...result]
          }
        ]
      });
    }
    if (dataSrc) {
      setLoading(true);
      loadData();
    }
  }, []);

  const lateralitySubtitle =
    laterality && Number(laterality) !== 2
      ? capitalize(Lateralities[laterality])
      : '';

  const subtitleCard = (
    <>
      {subTitle}
      {laterality && Number(laterality) !== 2 && (
        <>- [{<b>{lateralitySubtitle}</b>}]</>
      )}
    </>
  );

  return (
    <Fragment>
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardHeaderSubtitle>{subtitleCard}</CardHeaderSubtitle>
        <InputErrorMessage>{errorMessage}</InputErrorMessage>
      </CardHeader>
      <div style={{ height: 'calc(100% - 156px)', padding: '8px 24px' }}>
        {loading && <Loader />}
        {chartDataState?.data && (
          <LineChart
            chartData={chartDataState}
            title={title}
            tooltipSubTitle={subtitleCard}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SidePanelChartCard;
