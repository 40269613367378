import React, { useContext } from 'react';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import slugify from 'slugify';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import Button from 'components/button/Button';
import { StoreContext } from 'index';
import { appSyncClient } from 'services/aws/app-sync';
import {
  QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP,
  QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP_AND_PERSONS,
  QUERY_MAIL_GROWTH_DATA_OF_PERSONS_TO_PERSONS
} from 'services/aws/growthtracker-query';
import { MODAL_TYPES } from 'models/ModalData';
import { openWindowWithLoader } from 'utils/browser';
import { pollIsValidUrl } from 'utils/url';

const DownloadGrowthReportButton = ({ entityId, modalCallback }) => {
  const {
    uiState,
    authStore: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();

  const options = {};

  const reports = [
    {
      query: QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP,
      fct: 'downloadGroupGrowthReport'
    },
    {
      query: QUERY_DOWNLOAD_GROWTH_DATA_OF_GROUP_AND_PERSONS,
      fct: 'downloadGroupPersonsGrowthReport'
    },
    {
      query: QUERY_MAIL_GROWTH_DATA_OF_PERSONS_TO_PERSONS,
      fct: 'mailPersonsGrowthReport'
    }
  ];

  const downloadReport = async query => {
    try {
      uiState.increasePendingRequest();

      const { data, error } = await appSyncClient.query({
        query,
        variables: { entityId, meta: JSON.stringify({ lang: locale }) },
        fetchPolicy: 'network-only'
      });

      if (error) {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(messages.titleSessionReportDownloadFailed),
          message: messages.messageSessionReportDownloadFailed,
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      }

      const key = Object.keys(data)[0];
      if (data[key] !== null) {
        uiState.decreasePendingRequest();
        if (typeof data[key] === 'boolean') {
          // Download returns a boolean
          if (data[key]) {
            uiState.showModal({
              title: intl.formatMessage(
                key.includes('mail')
                  ? messages.titleSessionAllReportsMailedToPersonsSuccess
                  : messages.titleSessionAllReportsMailSuccess
              ),
              message: intl.formatMessage(
                key.includes('mail')
                  ? messages.messageGrowthReportMailedSuccess
                  : messages.messageSessionAllReportsMailSuccess
              ),
              dismissButton: false,
              type: MODAL_TYPES.ALERT
            });
          } else {
            throw new Error('Failed to send email');
          }
          return;
        } else if (
          typeof data[key] === 'string' &&
          data[key].includes('https:')
        ) {
          // Download returns a url so open a new window
          const url = data[key];
          try {
            const openWindow = openWindowWithLoader(data[key]);
            const response = await pollIsValidUrl(
              url.replace('&', '%2526'), // TODO Temp hack to allow & in url
              25,
              1000
            );
            if (response) {
              openWindow.location = url;
              openWindow.focus();
            }
          } catch (error) {
            // @ts-ignore
            uiState.showModal({
              title: intl.formatMessage(
                messages.titleSessionReportDownloadFailed
              ),
              message: messages.messageSessionReportDownloadFailed,
              dismissButton: false,
              type: MODAL_TYPES.WARNING
            });
          }
          uiState.decreasePendingRequest();
        } else {
          // No boolean or string, so throw an error
          throw new Error('Failed to download');
        }
      }
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionReportDownloadFailed),
        message: messages.messageSessionReportDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  const triggerDownloadMethod = async (query, options) => {
    // TODO options kunnen meesturen
    await downloadReport(query);
  };

  const showConfirmModal = callBack => {
    const okHandler = async () => {
      await callBack.method(callBack.query, options);
    };

    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmMailReportTitle),
      message: messages.modalConfirmMailReportMessage,
      dismissButton: true,
      type: MODAL_TYPES.WARNING,
      okHandler: okHandler
    });
  };

  return reports.map(report => {
    const generateCallback = method => {
      if (report.withOptions) {
        return modalCallback({
          method: triggerDownloadMethod,
          query: method.query
        });
      } else {
        if (method.fct.includes('mail')) {
          return showConfirmModal({
            method: triggerDownloadMethod,
            query: method.query
          });
        } else {
          return triggerDownloadMethod(method.query, options);
        }
      }
    };
    return DownloadBtn({
      report,
      onClick: () => generateCallback(report, options)
    });
  });
};

const DownloadBtn = ({ report, onClick }) => {
  const enIntl = createIntl({
    locale: 'en'
  });
  return (
    <Button
      menu
      key={report.fct}
      onClick={onClick}
      dataQa={`${slugify(
        messages[report.fct]
          ? enIntl.formatMessage(messages[report.fct]).toLowerCase()
          : report.fct
      )}-menu`}
    >
      {report.fct.includes('mail') ? (
        <Icon id="send" />
      ) : (
        <Icon id="download" />
      )}
      <FormattedMessage {...messages[`${report.fct}Button`]} />
    </Button>
  );
};

export default DownloadGrowthReportButton;
