import { useState, useContext } from 'react';
import Modal from 'components/modal/Modal';
import {
  ROUTE_RESULTS_ENTITY_GROUP,
  ROUTE_RESULTS_ENTITY_SPORTER,
  ROUTE_RESULTS_ENTITY_SESSION,
  ROUTE_RESULTS_ENTITY
} from 'routes/RouteList';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { StoreContext } from 'index';
import { ResultsModalView } from 'containers/pages/results/modals/results-modal-view';
import SelectGroupModal from 'containers/pages/results/modals/SelectGroupModal';
import BenchmarksChoiceView from 'containers/pages/results/modals/BenchmarksChoiceView';
import SelectSessionView from 'containers/pages/results/modals/SelectSessionView';
import SelectSporterView from 'containers/pages/results/modals/SelectSporterView';

const ResultsMainModal = ({
  entityId,
  master,
  onSubmitHandler,
  onCloseHandler,
  ...props
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const [view, setView] = useState(props.view || ResultsModalView.GROUP);

  const { push, goBack } = useHistory();
  const { pathname } = useLocation();

  const onSubmitClientHandler = (id, extraId, name) => {
    if (onSubmitHandler && typeof onSubmitHandler === 'function') {
      onSubmitHandler(id, extraId, name);
    }
  };

  const onSubmitMasterHandler = (id, extraId) => {
    let route;
    switch (view) {
      case ResultsModalView.GROUP:
        route = ROUTE_RESULTS_ENTITY_GROUP.replace(':groupId', id);
        break;
      case ResultsModalView.SPORTER:
        route = ROUTE_RESULTS_ENTITY_SPORTER.replace(':sporterId', id).replace(
          ':groupId',
          extraId
        );
        break;
      case ResultsModalView.SESSION:
        route = ROUTE_RESULTS_ENTITY_SESSION.replace(':sessionId', id);
        break;
      default:
        route = ROUTE_RESULTS_ENTITY;
    }

    route = generatePath(route, {
      popup: 'default',
      entityId: rootEntityId,
      benchmarkId: 'default'
    });

    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    }
    if (route !== pathname) {
      push(route);
    }
  };

  const onCancelHandler = () => {
    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    } else {
      goBack();
    }
  };

  const getView = view => {
    console.log('view', view);
    switch (view) {
      case ResultsModalView.CHOICE:
        return BenchmarksChoiceView;
      case ResultsModalView.GROUP:
        return SelectGroupModal;
      case ResultsModalView.SESSION:
        return SelectSessionView;
      case ResultsModalView.SPORTER:
        return SelectSporterView;
      default:
        return SelectGroupModal;
    }
  };

  const Component = getView(view);

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames="c-modal__content-bottom-auto"
    >
      <Component
        entityId={entityId}
        onChangeView={view => setView(view)}
        onCancelHandler={onCancelHandler}
        onSubmitHandler={master ? onSubmitMasterHandler : onSubmitClientHandler}
      />
    </Modal>
  );
};

export default ResultsMainModal;
