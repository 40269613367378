const ModalBody = props => {
  const { children, style, ...rest } = props;
  return (
    <div className="r-modal__body" style={style}>
      {children}
    </div>
  );
};

export default ModalBody;
