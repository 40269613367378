import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/modal/Modal';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { StoreContext } from 'index';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import PersonsTabs from 'containers/pages/persons/PersonsTabs';
import {
  addPersonToEntityFromPool,
  copyPersonToOtherEntity
} from 'services/aws/client-query';

const AddPersonsModal = ({ entityId, group, onClose, onDone }) => {
  const { uiState } = useContext(StoreContext);
  const [activePersonsTab, setActivePersonsTab] = useState(0);

  const currentGroup = useEntityContext(s => s.group);
  const selectedPersons = useEntityContext(s => s.selectedPersons);
  const setExcludedPersons = useEntityContext(s => s.setExcludedPersons);
  const clearSelected = useEntityContext(s => s.clearSelected);

  useEffect(() => {
    return () => {
      clearSelected();
    };
  }, []);

  useEffect(() => {
    if (group.clients) {
      setExcludedPersons(group.clients);
    }
  }, [group]);

  const onAddPersonsHandler = async () => {
    uiState.increasePendingRequest();
    const responses = [];
    for (const person of selectedPersons) {
      if (activePersonsTab === 0) {
        responses.push(
          await copyPersonToOtherEntity({
            oldEntityId: currentGroup.id,
            newEntityId: group.id,
            personId: person.id ?? person
          })
        );
        // if selected persons are from pool tab
      } else if (activePersonsTab === 1) {
        responses.push(
          await addPersonToEntityFromPool({
            topLevelEntityId: entityId,
            newEntityId: group.id,
            personId: person.id ?? person
          })
        );
      }
    }

    uiState.decreasePendingRequest();
    const errors = responses.filter(resp => resp.error);

    if (errors.length === 0) {
      onDone && onDone([group.id]);
    } else {
      console.log('ERRORS', errors);
    }
  };

  const onCloseHandler = () => {
    clearSelected();
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titleSessionAssignUsers} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal bodyWithTabs>
        <PersonsTabs
          onModal
          entityId={entityId}
          excludedPersons={group.clients}
          showPersonStatus={false}
          checkableGroups={false}
          onTabClick={(tab, index, selectedPersons) => {
            setActivePersonsTab(index);
          }}
        />
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            type="button"
            primary
            disabled={selectedPersons.length === 0}
            onClick={() => onAddPersonsHandler()}
          >
            <FormattedMessage
              {...messages.addSelectedPersonsButton}
              values={{ count: selectedPersons.length }}
            />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default AddPersonsModal;
