import { Breadcrumbs, BreadcrumbsWrapper } from 'components/breadcrumbs';
import {
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { MenuWrapper } from 'components/menu';
import { Card, CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import GrowthSettingsView from 'containers/pages/growthtracker/GrowthSettingsView';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import LinkAsButton from 'components/link/Link';
import { useContext } from 'react';
import { StoreContext } from 'index';
import DownloadGrowthReportButton from 'containers/partials/buttons/DownloadGrowthReportButton';

function GrowthPanelHeader({ breadcrumbs, panelChoices }) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { setShowColumnVisibilityModal } = useGrowthPanelContext();
  const group = useEntityContext(s => s.group);

  return (
    <PanelHeader>
      <BreadcrumbsWrapper>
        <Breadcrumbs benchmarks>{breadcrumbs}</Breadcrumbs>
        <PanelTitleButtonsGroup>
          <MenuWrapper
            asModal
            trigger={<PanelTitleButton small icon="question" />}
          >
            <Card helper>
              <CardBody benchmarks>
                <p>
                  All this data is taken reflecting the results at the last
                  measurement moment. Check how to interpret these data at
                  <LinkAsButton
                    to={{ pathname: 'https://hylyght.com' }}
                    external
                    inline
                  >
                    hylyght.com
                  </LinkAsButton>
                </p>
              </CardBody>
            </Card>
          </MenuWrapper>
          <MenuWrapper trigger={<PanelTitleButton />}>
            {user.superadmin && (
              <Button menu onClick={() => setShowColumnVisibilityModal(true)}>
                {`Superadmin `}
                <FormattedMessage
                  {...messages.growthtrackerMenuSetColumnVisibility}
                />
                <Icon id="eye-enabled" strokeColor="color-neutral-dark" />
              </Button>
            )}
            <DownloadGrowthReportButton entityId={group.id} />
          </MenuWrapper>
        </PanelTitleButtonsGroup>
      </BreadcrumbsWrapper>

      <PanelTitleWrapper>
        <PanelTitle>
          {panelChoices ? (
            <MenuWrapper trigger={<span>{group.name}</span>}>
              {panelChoices}
            </MenuWrapper>
          ) : (
            `${group.name}`
          )}
        </PanelTitle>
        <PanelSubtitle>
          <FormattedMessage
            {...messages.benchmarksGroupPageSubtitle}
            values={{
              name: group.name
            }}
          />
        </PanelSubtitle>
      </PanelTitleWrapper>
      <div
        className="o-layout o-layout--small"
        style={{ width: 'calc(100% + 16px)' }}
      >
        <GrowthSettingsView />
      </div>
    </PanelHeader>
  );
}

export default GrowthPanelHeader;
