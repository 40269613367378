import React, { useContext } from 'react';
import { openWindowWithLoader } from 'utils/browser';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadFunctions } from 'services/aws/session-query';
import { pollIsValidUrl } from 'utils/url';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { appSyncClient } from 'services/aws/app-sync';
import slugify from 'slugify';
import { useSessionContext } from 'contexts/SessionContext';

function DownloadReportsButton({ testData, person, type, modalCallback }) {
  const { uiState } = useContext(StoreContext);
  const { session, options } = useSessionContext();
  const intl = useIntl();
  let reports = session?.config?.reports?.[type];

  if (!reports) {
    if (type === 'group') {
      reports = [
        {
          fct: 'downloadSessionReports',
          title: 'download_session_reports',
          withOptions: true
        },
        {
          fct: 'mailSessionAllReportOfTestSession',
          title: 'mail_all_report',
          withOptions: true
        }
      ];
    } else if (type === 'individual') {
      reports = [
        {
          fct: 'downloadSessionReport',
          title: 'download_session_report'
        },
        {
          fct: 'mailSessionReport',
          title: 'mail_individual_report'
        }
      ];
    }
  }

  const downloadSession = async (query, variables) => {
    const result = await appSyncClient.query({
      query,
      variables,
      fetchPolicy: 'network-only'
    });
    if (result) {
      return result;
    }
  };

  const downloadReport = async (query, variables) => {
    try {
      uiState.increasePendingRequest();
      const { data, error } = await downloadSession(query, variables);

      if (error) {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(messages.titleSessionReportDownloadFailed),
          message: messages.messageSessionReportDownloadFailed,
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      }

      const key = Object.keys(data)[0];
      if (data[key] !== null) {
        uiState.decreasePendingRequest();
        if (typeof data[key] === 'boolean') {
          // Download returns a boolean
          if (data[key]) {
            uiState.showModal({
              title: intl.formatMessage(
                key.includes('mail')
                  ? type === 'individual'
                    ? messages.titleSessionReportMailedToPersonSuccess
                    : messages.titleSessionAllReportsMailedToPersonsSuccess
                  : messages.titleSessionAllReportsMailSuccess
              ),
              message: key.includes('mail')
                ? type === 'individual'
                  ? messages.messageSessionReportMailedToPersonSuccess
                  : messages.messageSessionAllReportsMailedToPersonsSuccess
                : messages.messageSessionAllReportsMailSuccess,
              dismissButton: false,
              type: MODAL_TYPES.ALERT
            });
          } else {
            throw new Error('Failed to send email');
          }
          return;
        } else if (
          typeof data[key] === 'string' &&
          data[key].includes('https:')
        ) {
          // Download returns a url so open a new window
          const url = data[key];
          try {
            const openWindow = openWindowWithLoader(data[key]);
            const response = await pollIsValidUrl(
              url.replace('&', '%2526'), // TODO Temp hack to allow & in url
              25,
              1000
            );
            if (response) {
              openWindow.location = url;
              openWindow.focus();
            }
          } catch (error) {
            // @ts-ignore
            uiState.showModal({
              title: intl.formatMessage(
                messages.titleSessionReportDownloadFailed
              ),
              message: messages.messageSessionReportDownloadFailed,
              dismissButton: false,
              type: MODAL_TYPES.WARNING
            });
          }
          uiState.decreasePendingRequest();
        } else {
          // No boolean or string, so throw an error
          throw new Error('Failed to download');
        }
      }
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionReportDownloadFailed),
        message: messages.messageSessionReportDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  const triggerDownloadMethod = async (method, options) => {
    if (downloadFunctions[method]) {
      const query = downloadFunctions[method].query;
      const params = downloadFunctions[method].params;
      const { benchmark, ...restOptions } = options;
      const data = {
        ...session,
        testSessionId: session.id,
        benchmarkId: benchmark || null,
        testDataId: testData?.id || null,
        blackAndWhite: false,
        personId: person?.id || null,
        options: restOptions || {}
      };
      const args = params.reduce((acc, param) => {
        acc[param] = data[param];
        return acc;
      }, {});

      await downloadReport(query, args);
    }
  };

  const showConfirmModal = callBack => {
    const okHandler = async () => {
      await callBack.method(callBack.query, options);
    };

    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmMailReportTitle),
      message: messages.modalConfirmMailReportMessage,
      dismissButton: true,
      type: MODAL_TYPES.WARNING,
      okHandler: okHandler
    });
  };

  return reports.map(report => {
    const generateCallback = method => {
      if (report.withOptions) {
        return modalCallback({
          method: triggerDownloadMethod,
          query: method
        });
      } else {
        if (method.includes('mail')) {
          if (type === 'individual') {
            if (!person.hasEmail()) {
              uiState.showModal({
                title: intl.formatMessage(
                  messages.modalReportSendToSporterFailedNoEmailTitle
                ),
                message: messages.modalReportSendToSporterFailedNoEmailMessage,
                dismissButton: false,
                type: MODAL_TYPES.WARNING
              });
              return;
            }
          }
          return showConfirmModal({
            method: triggerDownloadMethod,
            query: method
          });
        } else {
          return triggerDownloadMethod(method, options);
        }
      }
    };
    return DownloadBtn({
      report,
      type,
      onClick: () => generateCallback(report.fct, options)
    });
  });
}

const DownloadBtn = ({ report, type, onClick }) => {
  const enIntl = createIntl({
    locale: 'en'
  });
  return (
    <Button
      key={report.fct}
      menu
      onClick={onClick}
      dataQa={`${slugify(
        messages[report.fct]
          ? enIntl.formatMessage(messages[report.fct]).toLowerCase()
          : report.fct
      )}-menu`}
    >
      {report.title.includes('mail') ? (
        <Icon id="send" />
      ) : (
        <Icon id="download" />
      )}
      {messages[`reportDownloadBtn_${report.title}`] ? (
        <FormattedMessage {...messages[`reportDownloadBtn_${report.title}`]} />
      ) : (
        <FormattedMessage {...messages[`reportDownloadBtn_${type}_report`]} />
      )}
    </Button>
  );
};

export default DownloadReportsButton;
