import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import messages from 'messages';
import { GrowthTrackerModalView } from './growth-tracker-modal-view';
import ButtonCard from 'components/button/ButtonCard';

/**
 * TODO: Deprecated.
 * COMPONENT NOT USED, TESTED FOR FORM REFACTOR
 */

// TODO: Currently unused, we could rid of it, couldn't we?
class GrowthChoiceView extends React.Component {
  state = {};

  render() {
    const { onChangeView, intl } = this.props;
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage {...messages.benchmarksChoiceModalTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage {...messages.benchmarksChoiceModalSubTitle} />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody modal>
          <ul className="o-list-bare c-card__list-packages c-card__list-packages-modal">
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                disabled
                onClick={() => onChangeView(GrowthTrackerModalView.SPORTER)}
                iconId="sporters"
                iconFillColor="color-neutral-x-light"
                title={intl.formatMessage(
                  messages.benchmarksChoiceModalSportersTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.benchmarksChoiceModalSportersDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.benchmarksChoiceModalSportersDescriptionButton
                )}
              />
            </li>
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                onClick={() => onChangeView(GrowthTrackerModalView.GROUP)}
                iconId="group-new-icon"
                title={intl.formatMessage(
                  messages.benchmarksChoiceModalGroupsTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.benchmarksChoiceModalGroupsDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.benchmarksChoiceModalGroupsDescriptionButton
                )}
              />
            </li>
            <li className="o-list-bare__item c-card__list-packages-item">
              <ButtonCard
                disabled
                onClick={() => onChangeView(GrowthTrackerModalView.SESSION)}
                iconId="sessions-white"
                title={intl.formatMessage(
                  messages.benchmarksChoiceModalSessionsTitleButton
                )}
                subtitle={intl.formatMessage(
                  messages.benchmarksChoiceModalSessionsDescriptionButton
                )}
                ariaLabel={intl.formatMessage(
                  messages.benchmarksChoiceModalSessionsDescriptionButton
                )}
              />
            </li>
          </ul>
        </CardBody>
        <CardFooter modal />
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(GrowthChoiceView))
);
