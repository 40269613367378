import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';

import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter, CardBodyTools } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import Entity from 'models/Entity';
import UserModel from 'models/User';
import { sortEntities, sortUsers } from 'utils/sort';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { getNamePrefix } from 'utils/name-prefix';
import Loader from 'components/loader/Loader';

class SelectSporterView extends React.Component {
  state = {
    entityId: this.props.entityId,
    activeSporterId: null,
    activeName: null
  };

  onBackHandler = () => {
    this.setState({
      entityId: this.entity.parentId,
      activeSporterId: null,
      activeName: null
    });
  };

  onGroupClickHandler = id => {
    this.setState({ entityId: id, activeSporterId: null, activeName: null });
  };

  onSporterClickHandler = (id, name) => {
    this.setState({ activeSporterId: id, activeName: name });
  };

  render() {
    const {
      authStore: { user },
      onCancelHandler,
      onSubmitHandler
    } = this.props;
    const { entityId, activeSporterId, activeName } = this.state;
    return (
      <Fragment>
        <CardHeader modal>
          <CardHeaderTitle>
            <FormattedMessage {...messages.benchmarksSubChoiceModalTitle} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            <FormattedMessage
              {...messages.benchmarksSubSporterChoiceModalSubtitle}
            />
          </CardHeaderSubtitle>
        </CardHeader>
        <CardBody
          modal
          extraClassNames={'u-padding-vertical-none u-padding-horizontal-small'}
        >
          {entityId !== user.rootEntityId && (
            <CardBodyTools style={{ flexWrap: 'wrap' }}>
              {/* Add a search field. And why the if? */}
              <Button rounded light onClick={e => this.onBackHandler(e)}>
                <Icon id="back-nav" />
                <FormattedMessage {...messages.benchmarksBackButton} />
              </Button>
            </CardBodyTools>
          )}
          <List>
            <Query
              query={QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID}
              variables={{
                id: entityId
              }}
              fetchPolicy="cache-and-network"
            >
              {result => {
                const { loading, error, data } = result;
                if (loading) return <Loader />;
                if (error) return <p>Error! {error.message}</p>;
                this.entity = new Entity(data.getEntity);
                const sortedEntities = sortEntities(this.entity.subEntities);
                const sortedSporters = sortUsers(this.entity.clients);
                return (
                  <Fragment>
                    <ListBody>
                      {sortedEntities.map(group => {
                        return (
                          <GroupListItem
                            id={group.id}
                            key={group.id}
                            group={group}
                            onClick={() => this.onGroupClickHandler(group.id)}
                          />
                        );
                      })}
                    </ListBody>
                    <ListBody>
                      {sortedSporters.map(user => {
                        const person = new UserModel(user);
                        return (
                          <ClientListItem
                            key={person.id}
                            user={user}
                            onClick={() =>
                              this.onSporterClickHandler(
                                person.id,
                                user.firstname + user.lastname
                              )
                            }
                            active={person.id === activeSporterId}
                          />
                        );
                      })}
                    </ListBody>
                  </Fragment>
                );
              }}
            </Query>
          </List>
        </CardBody>
        <CardFooter modal>
          <ButtonsGroup>
            <Button secondary onClick={() => onCancelHandler()}>
              <FormattedMessage {...messages.benchmarksCancelButton} />
            </Button>
            <Button
              disabled={activeSporterId === null}
              primary
              onClick={e =>
                onSubmitHandler(activeSporterId, entityId, activeName)
              }
            >
              <FormattedMessage {...messages.benchmarksViewButton} />
            </Button>
          </ButtonsGroup>
        </CardFooter>
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('authStore', 'routing')(SelectSporterView))
);

const GroupListItem = ({ group: { id, name, clients }, active, onClick }) => (
  <ListItem group active={active} onClick={onClick}>
    <ListItemStatus itemId={id} statusIcon="group" />
    <ListItemLabelWrapper>
      <ListItemLabel label>{name}</ListItemLabel>
      <ListItemLabel info>
        <FormattedMessage
          {...messages.benchmarksListItemSportersCount}
          values={{ count: clients.length }}
        />
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);

const ClientListItem = ({
  active,
  user: { id, firstname, lastname },
  onClick
}) => (
  <ListItem active={active} onClick={onClick}>
    <ListItemStatus itemId={id}>
      {getNamePrefix(firstname, lastname)}
    </ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>
        {firstname} {lastname}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
